import { HelseRegion } from '../types/ExtendEnums';
import {
  BehandlingsstederBehandlingssted,
  Behandlingstilbud,
  Adresse,
} from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import { ActiveFilter } from '@helsenorge/filter/components/filter/useFilters';

import { sortFilterValues } from '../utils/filters';
import { hentHelseregionString } from '../utils/strings';

const filterValueOffentlige = 'offentlige';
const filterValuePrivate = 'private';

export const adresseHasSearchString = (adresse: Adresse | null, searchText: string): boolean => {
  return adresse
    ? (!!adresse.gateadresse && adresse.gateadresse.toLowerCase().includes(searchText)) ||
        (!!adresse.postnr && adresse.postnr.includes(searchText)) ||
        (!!adresse.poststed && adresse.poststed.toLowerCase().includes(searchText))
    : false;
};

export type FilterObject = { name?: string; id?: string; values: Array<FilterValue>; index: number };
export type FilterValue = { checked?: boolean; id?: string; value: string; displayName?: string };

export const getFilteredBehandlingssteder = (
  resources: HNVelgBehandlingsstedFrontend,
  behandlingssteder: Array<BehandlingsstederBehandlingssted>,
  activeFilters: Array<ActiveFilter> | undefined,
  searchText?: string
): Array<BehandlingsstederBehandlingssted> => {
  const helseregionCheckedFilters =
    activeFilters?.filter(filter => filter.name.split(';')[0] === resources.filterHelseregion).map(filter => filter.value) ?? [];
  const behandlingstilbudCheckedFilters =
    activeFilters?.filter(filter => filter.name.split(';')[0] === resources.filterBehandlingstilbud).map(filter => filter.value) ?? [];
  const behandlingsstedCheckedFilters =
    activeFilters?.filter(filter => filter.name.split(';')[0] === resources.filterBehandlingssted).map(filter => filter.value) ?? [];
  const offentligeFilterChecked = behandlingsstedCheckedFilters.some(filter => filter === filterValueOffentlige);
  const privateFilterChecked = behandlingsstedCheckedFilters.some(filter => filter === filterValuePrivate);

  return helseregionCheckedFilters.length ||
    behandlingstilbudCheckedFilters.length ||
    searchText ||
    offentligeFilterChecked ||
    privateFilterChecked
    ? behandlingssteder.filter((behandlingssted: BehandlingsstederBehandlingssted) => {
        const lowerCaseSearchText = searchText ? searchText.toLowerCase() : '';

        const searchTextMatch = searchText
          ? (!!behandlingssted.navn && behandlingssted.navn.toLowerCase().includes(lowerCaseSearchText)) ||
            (!!behandlingssted.navnBedriftsregister && behandlingssted.navnBedriftsregister.toLowerCase().includes(lowerCaseSearchText)) ||
            (!!behandlingssted.orgnr && behandlingssted.orgnr.toString().includes(lowerCaseSearchText)) ||
            (behandlingssted.behandlingstilbud &&
              behandlingssted.behandlingstilbud.some(tilbud => !!tilbud.navn && tilbud.navn.toLowerCase().includes(lowerCaseSearchText))) ||
            adresseHasSearchString(behandlingssted.besoksAdresse, lowerCaseSearchText) ||
            adresseHasSearchString(behandlingssted.postAdresse, lowerCaseSearchText)
          : true;

        let helseregionMatch = helseregionCheckedFilters.length ? false : true;
        if (helseregionCheckedFilters.length && !!behandlingssted.helseRegioner && behandlingssted.helseRegioner.length) {
          for (const helseRegion of behandlingssted.helseRegioner) {
            if (helseregionCheckedFilters.includes(helseRegion)) {
              helseregionMatch = true;
              break;
            }
          }
        }

        const behandlingstilbudMatch = behandlingstilbudCheckedFilters.length
          ? !!behandlingssted.behandlingstilbud &&
            behandlingssted.behandlingstilbud.some(tilbud => !!tilbud.navn && behandlingstilbudCheckedFilters.includes(tilbud.navn))
          : true;

        const offentligeMatch = offentligeFilterChecked && !privateFilterChecked ? !behandlingssted.erPrivat : true;
        const privateMatch = privateFilterChecked && !offentligeFilterChecked ? behandlingssted.erPrivat : true;

        return searchTextMatch && helseregionMatch && behandlingstilbudMatch && offentligeMatch && privateMatch;
      })
    : behandlingssteder;
};

export const getInitialFilters = (
  resources: HNVelgBehandlingsstedFrontend,
  behandlingssteder: Array<BehandlingsstederBehandlingssted>
): Array<FilterObject> => {
  let helseregionFilterValues: Array<FilterValue> = [];
  let behandlingstilbudFilterValues: Array<FilterValue> = [];
  let behandlingsstederFilterValues: Array<FilterValue> = [];
  let filterOffentlige = false;
  let filterPrivate = false;

  behandlingssteder.forEach((behandlingssted: BehandlingsstederBehandlingssted, index: number) => {
    if (!!behandlingssted.helseRegioner && behandlingssted.helseRegioner.length) {
      for (const helseRegion of behandlingssted.helseRegioner) {
        if (
          !helseregionFilterValues.some(
            filterValue => helseRegion !== HelseRegion.Unknown && !!helseRegion && filterValue.value === helseRegion
          )
        ) {
          helseregionFilterValues = helseregionFilterValues.concat({
            checked: false,
            id: `helseregion-${helseRegion}`,
            displayName: hentHelseregionString(helseRegion, resources),
            value: helseRegion ? helseRegion : '',
          });
        }
      }
    }

    if (behandlingssted.behandlingstilbud) {
      behandlingssted.behandlingstilbud.forEach((tilbud: Behandlingstilbud, index2: number) => {
        if (!behandlingstilbudFilterValues.some(filterValue => filterValue.value === tilbud.navn)) {
          behandlingstilbudFilterValues = behandlingstilbudFilterValues.concat({
            checked: false,
            id: `behandlingstilbud${index}${index2}`,
            displayName: tilbud.navn ? tilbud.navn : '',
            value: tilbud.navn ? tilbud.navn : '',
          });
        }
      });
    }

    if (!filterOffentlige && !behandlingssted.erPrivat) {
      filterOffentlige = true;
      behandlingsstederFilterValues = behandlingsstederFilterValues.concat({
        checked: false,
        id: `behandlingssted-offentlige`,
        displayName: resources.filterBehandlingsstedOffentlige,
        value: filterValueOffentlige,
      });
    }
    if (!filterPrivate && behandlingssted.erPrivat) {
      filterPrivate = true;
      behandlingsstederFilterValues = behandlingsstederFilterValues.concat({
        checked: false,
        id: `behandlingssted-private`,
        displayName: resources.filterBehandlingsstedPrivate,
        value: filterValuePrivate,
      });
    }
  });

  return [
    {
      index: 0,
      values: sortFilterValues(helseregionFilterValues),
      name: resources.filterHelseregion,
    },
    {
      index: 1,
      values: sortFilterValues(behandlingstilbudFilterValues),
      name: resources.filterBehandlingstilbud,
    },
    {
      index: 2,
      values: sortFilterValues(behandlingsstederFilterValues),
      name: resources.filterBehandlingssted,
    },
  ];
};
