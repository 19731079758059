import React from 'react';

import { Link } from 'react-router-dom';

import { ColumnNames, Urls } from '../types/ExtendEnums';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import { Cell } from '@helsenorge/data-table/components/table';

import { BehandlingTabellData, getVentetidElement } from './table-helper';
import { hentAntallBehandlingerString } from '../ventetider-for-behandling/helper';

interface Props {
  rowData?: BehandlingTabellData;
  columnName: string;
  resources: HNVelgBehandlingsstedFrontend;
}

export default class BehandlingCell extends React.PureComponent<Props> {
  render(): JSX.Element | null {
    const { rowData, columnName, resources, ...other } = this.props;

    if (rowData) {
      switch (columnName) {
        case ColumnNames.Behandling:
          return (
            <Cell {...other}>
              <Link to={`${Urls.VentetiderForBehandling}?bid=${rowData.behandlingData.behandlingKode}`}>{rowData.behandlingData.navn}</Link>
            </Cell>
          );

        case ColumnNames.Antall:
          return (
            <Cell {...other}>
              {rowData.behandlingData.antall ? (
                <>
                  <span>{hentAntallBehandlingerString(resources, rowData.behandlingData.antall)}</span>
                  <br />
                  <span className="vbs-main__subtitle">{`${resources.tabellAntallBehandlingerIAar} ${rowData.behandlingData.antallAr}`}</span>
                </>
              ) : (
                <span className="vbs-main__subtitle">{resources.behandlingsstederIkkeBeregnet}</span>
              )}
            </Cell>
          );
        default:
          return (
            <Cell {...other}>
              {getVentetidElement(
                resources,
                rowData.behandlingData?.ventetider?.find(v => v.type === columnName)
              )}
            </Cell>
          );
      }
    }

    return null;
  }
}
