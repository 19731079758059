import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { ColumnNames } from '../types/ExtendEnums';
import { HentBehandlingLenkerResponse } from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import Checkbox from '@helsenorge/designsystem-react/components/Checkbox';
import { simpleConfig } from '@helsenorge/designsystem-react/components/Table';

import PageHeader from '@helsenorge/core-framework/page-header';
import PageLoader from '@helsenorge/core-framework/page-loader/page-loader';
import layoutChange, { OriginalProps as LayoutChangeProps } from '@helsenorge/core-utils/hoc/layout-change';
import { Table, Column, ExpandableRow } from '@helsenorge/data-table/components/table';
import Filter, { DropDownFilterOption } from '@helsenorge/filter/components/filter';
import { useFilters } from '@helsenorge/filter/components/filter/useFilters';
import { trackSearch } from '@helsenorge/framework-utils/adobe-analytics';
import { getSidetittelId } from '@helsenorge/framework-utils/hn-page';
import { get } from '@helsenorge/framework-utils/hn-proxy-service';

import AntallCell from './antall-cell';
import BehandlingsstedExpanded from './behandlingssted-expanded';
import { getTabellData, getInitialFilters, getTableCaption } from './helper';
import VentetidCell from './ventetid-cell';
import { VentetiderForBehandlingByBid } from './ventetider-for-behandling';
import { Filters } from '../behandlingssteder';
import { FilterObject } from '../behandlingssteder/filters-helper';
import BehandlingsstedCell from '../shared/behandlingssted-cell';
import { getFiltersStrings } from '../utils/filters';

import './styles.scss';

interface Props extends LayoutChangeProps {
  resources: HNVelgBehandlingsstedFrontend;
  loading: boolean;
  error?: string;
  ventetiderForBehandlingByBid?: VentetiderForBehandlingByBid;
}

const VentetiderForBehandlingComponent: React.FunctionComponent<Props> = ({
  resources,
  loading,
  error,
  ventetiderForBehandlingByBid,
}: Props) => {
  const [searchparams] = useSearchParams();
  const harAntall = ventetiderForBehandlingByBid?.harAntall;

  const { filters, setDelayedFilter, updateFilters, activeFilters } = useFilters<Filters>();

  const [sortColumnKey, setSortColumnKey] = React.useState(ColumnNames.Behandlingssted);
  const [sortDirIsAsc, setSortDirIsAsc] = React.useState(true);
  const [filterData, setFilterData] = React.useState<Array<FilterObject>>([]);
  const [tabellData, setTabellData] = React.useState(getTabellData(resources, []));

  const onSort = (sortColumnKey: ColumnNames, sortAscending: boolean): void => {
    setSortColumnKey(sortColumnKey);
    setSortDirIsAsc(sortAscending);
  };

  const filtersStrings = getFiltersStrings(resources);
  const caption = getTableCaption(
    resources,
    ventetiderForBehandlingByBid && ventetiderForBehandlingByBid.navn ? ventetiderForBehandlingByBid.navn : ''
  );

  async function fetchLinks(orgNr: number): Promise<HentBehandlingLenkerResponse | null> {
    const url = `api/v1/BehandlingLenke`;
    try {
      const response = await get<HentBehandlingLenkerResponse>('VelgBehandlingssted', url, {
        behandlingId: searchparams.get('bid'),
        orgNr: orgNr,
      });
      return response;
    } catch {
      return null;
    }
  }

  React.useEffect(() => {
    if (ventetiderForBehandlingByBid && ventetiderForBehandlingByBid.behandlingssteder) {
      setFilterData(getInitialFilters(resources, ventetiderForBehandlingByBid.behandlingssteder));
      setTabellData(getTabellData(resources, ventetiderForBehandlingByBid.behandlingssteder));

      if (ventetiderForBehandlingByBid.navn) {
        trackSearch(
          ventetiderForBehandlingByBid.navn,
          'Internsøk – Velg behandlingssted',
          ventetiderForBehandlingByBid.behandlingssteder.length
        );
      }
    }
  }, [ventetiderForBehandlingByBid]);

  React.useEffect(() => {
    if (ventetiderForBehandlingByBid && ventetiderForBehandlingByBid.behandlingssteder) {
      setTabellData(getTabellData(resources, ventetiderForBehandlingByBid.behandlingssteder, activeFilters));
    }
  }, [filters]);

  const renderFilterDropdowns = (filterData: FilterObject[]): DropDownFilterOption[] => {
    return filterData.map((filter: FilterObject) => ({
      placeholder: filter?.name,
      label: filter?.name,
      type: 'dropdown',
      onClose: () => updateFilters(),
      children: filter?.values?.map(fv => (
        <Checkbox
          key={`${filter.name}${fv.id}`}
          label={fv.displayName}
          value={fv.value}
          checked={filters && !!filters[filter.name + ';' + fv.value]}
          onChange={e => {
            setDelayedFilter(filter.name + ';' + fv.value, e.target.checked ? e.target.value : '');
          }}
        />
      )),
    }));
  };

  return (
    <PageLoader resources={resources} loading={loading} error={error} labelId={getSidetittelId()}>
      <PageHeader
        subtitle={resources.ventetiderForBehandlingTittelPrefix}
        title={ventetiderForBehandlingByBid?.navn ?? ''}
        description={ventetiderForBehandlingByBid && !!ventetiderForBehandlingByBid.kommentar ? ventetiderForBehandlingByBid.kommentar : ''}
        hjelpetekstId="ventetiderforbehandling"
        showHjelpetrigger
      />
      <Filter
        showFilters
        activeFilters={activeFilters}
        resources={filtersStrings}
        isAlwaysOpen
        statusText={`${tabellData?.length} ${resources.ventetiderForBehandlingFilterUnit}`}
        filterOptions={renderFilterDropdowns(filterData)}
      />
      <Table
        id="ventetider-for-behandling-table"
        data={tabellData}
        tableClassNames={`vbs-ventetider-for-behandling__behandlingssted-tabell ${
          harAntall ? 'vbs-ventetider-for-behandling__behandlingssted-tabell--four-columns' : ''
        }`}
        initialSortColumnKey={sortColumnKey}
        initialSortDirIsAsc={sortDirIsAsc}
        sortColumnKey={sortColumnKey}
        sortDirIsAsc={sortDirIsAsc}
        onSortColumn={onSort}
        showMoreButtonClasses="vbs-main__hent-flere"
        showMorePageSize={20}
        showMoreButtonText={resources.hentFlere}
        emptyContentText={resources.tabellIngenTreff}
        useNumericSort
        caption={caption}
        noAnchorLinkWrapper
        breakpointConfig={simpleConfig}
      >
        <Column
          name={ColumnNames.Behandlingssted}
          title={resources.ventetiderForBehandlingTabellBehandlingssted}
          sortable
          cell={<BehandlingsstedCell />}
        />
        <Column
          name={ColumnNames.Ventetid}
          title={resources.ventetiderForBehandlingTabellVentetid}
          sortable
          cell={<VentetidCell resources={resources} />}
        />
        {harAntall && (
          <Column
            name={ColumnNames.Antall}
            title={resources.ventetiderForBehandlingTabellAntall}
            sortable
            cell={<AntallCell resources={resources} />}
          />
        )}

        <ExpandableRow open tableId="ventetider-for-behandling-table">
          <BehandlingsstedExpanded resources={resources} getLinks={fetchLinks} />
        </ExpandableRow>
      </Table>
    </PageLoader>
  );
};

export default layoutChange(VentetiderForBehandlingComponent);
