import React from 'react';

import {
  ChangeEvent,
  OnSuggestionsClearRequested,
  SuggestionsFetchRequested,
  OnSuggestionSelected,
  SuggestionsFetchRequestedParams,
} from 'react-autosuggest';

import Autosuggest, { Suggestion } from '@helsenorge/autosuggest/components/autosuggest';

import './styles.scss';

interface Props {
  label: string;
  allSuggestions: Array<Suggestion>;
  handleSuggestionSelected: (suggestion: Suggestion, e?: React.FormEvent<HTMLElement>) => void;
}

const AutosuggestComponent: React.FunctionComponent<Props> = ({ label, allSuggestions, handleSuggestionSelected }: Props) => {
  const [value, setValue] = React.useState('');
  const [suggestions, setSuggestions] = React.useState([] as Array<Suggestion>);

  const onChange = (_e: React.FormEvent<HTMLInputElement>, params: ChangeEvent): void => {
    setValue(params.newValue);
  };

  const onSuggestionsClearRequested: OnSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const hasMatchingLabel = (suggestion: Suggestion, toMatch: string): boolean => {
    return !!suggestion.label && suggestion.label.toLowerCase().includes(toMatch.toLowerCase().trim());
  };

  const hasMatchingSynonym = (suggestion: Suggestion, toMatch: string): boolean => {
    return !!suggestion.optionalLabel && suggestion.optionalLabel.toLowerCase().includes(toMatch.toLowerCase().trim());
  };

  const onSuggestionsFetchRequested: SuggestionsFetchRequested = (requestParams: SuggestionsFetchRequestedParams) => {
    if (requestParams.value.trim().length >= 2) {
      setSuggestions(
        allSuggestions
          .filter((suggestion: Suggestion) => {
            return hasMatchingLabel(suggestion, requestParams.value) || hasMatchingSynonym(suggestion, requestParams.value);
          })
          .map(suggestion => {
            let points = 0;

            if (hasMatchingLabel(suggestion, requestParams.value)) {
              points += 2;
            }

            if (hasMatchingSynonym(suggestion, requestParams.value)) {
              points += 1;
            }

            return { ...suggestion, points };
          })
          .sort((a, b) => b.points - a.points)
      );
    } else {
      setSuggestions([]);
    }
  };

  const onSuggestionSelected: OnSuggestionSelected<Suggestion> = (e, data) => {
    handleSuggestionSelected(data.suggestion, e);
  };

  const highlightText = (text: string, highlight: string): JSX.Element | string => {
    const highlightStartIndex: number = text.toLowerCase().indexOf(highlight.toLowerCase());
    if (highlightStartIndex >= 0) {
      const highlightEndIndex: number = highlightStartIndex + highlight.length;
      const textStart: string = text.substr(0, highlightStartIndex);
      const textHighlight: string = text.substr(highlightStartIndex, highlight.length);
      const textEnd: string = text.substr(highlightEndIndex);
      return (
        <span>
          {textStart}
          <strong>{textHighlight}</strong>
          {textEnd}
        </span>
      );
    }
    return text;
  };

  const autoSuggestionClass = 'vbs-autosuggestion';

  const showSynonym = (label: string): JSX.Element | null => {
    const search = value.toLowerCase().trim();

    const matchingSynonym = label.split(',').filter(x => x.toLowerCase().includes(search));

    const highlightedText = highlightText(matchingSynonym.join(', ').trim(), value);

    if (matchingSynonym.length > 0) {
      return (
        <span className={`${autoSuggestionClass}__synonym`}>
          {' ('}
          {highlightedText}
          {')'}
        </span>
      );
    }

    return null;
  };
  return (
    <Autosuggest
      inputProps={{
        id: 'vbs-autosuggest',
        value: value,
        onChange: onChange,
        type: 'search',
      }}
      className={autoSuggestionClass}
      label={label}
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      renderSuggestion={(s): JSX.Element => (
        <>
          {highlightText(`${s.label}`, value)}
          {s.optionalLabel && showSynonym(s.optionalLabel)}
        </>
      )}
      onSuggestionSelected={onSuggestionSelected}
    />
  );
};

export default AutosuggestComponent;
