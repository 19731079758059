import React from 'react';

import { ColumnNames } from '../../types/ExtendEnums';
import {
  VentetiderForBehandlingBehandlingssted,
  BehandlingsstederBehandlingssted,
} from '../../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';

import { Cell } from '@helsenorge/data-table/components/table';

import styles from './styles.module.scss';

export interface BehandlingsstedTabellData {
  behandlingsstedData: VentetiderForBehandlingBehandlingssted & BehandlingsstederBehandlingssted;
  [ColumnNames.Behandlingssted]: string;
}

interface Props {
  rowData?: BehandlingsstedTabellData;
}

export default class BehandlingsstedCell extends React.PureComponent<Props> {
  render(): JSX.Element | null {
    const { rowData, ...other } = this.props;

    return (
      <Cell {...other}>
        {rowData && (
          <div className={styles['behandlingssted-cell__data']}>
            <strong>{rowData.behandlingsstedData.navn}</strong>
            <br />

            {!!rowData.behandlingsstedData.besoksAdresse && <span>{rowData.behandlingsstedData.besoksAdresse.gateadresse}</span>}
            {!!rowData.behandlingsstedData.kommune && <span>{`, ${rowData.behandlingsstedData.kommune}`}</span>}
          </div>
        )}
      </Cell>
    );
  }
}
