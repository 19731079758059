import { HelseRegion } from '../types/ExtendEnums';
import { Ventetid } from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

export const hentUkeString = (resources: HNVelgBehandlingsstedFrontend, uker: number): string => {
  return uker === 1 ? resources.tabellUke : resources.tabellUker;
};

export const hentVentetidString = (resources: HNVelgBehandlingsstedFrontend, ventetider?: Array<Ventetid> | null): string => {
  if (ventetider && ventetider.length) {
    const ventetiderOverNull = ventetider.filter(ventetid => ventetid.ukerTotalt > 0);

    if (ventetiderOverNull.length === 0) {
      return resources.ikkeOppdatert;
    }

    if (ventetiderOverNull.length === 1) {
      return `${ventetiderOverNull[0].ukerTotalt} ${hentUkeString(resources, ventetiderOverNull[0].ukerTotalt)}`;
    }

    const sortedVentetider: Array<Ventetid> = Object.assign([], ventetiderOverNull);
    sortedVentetider.sort((v1: Ventetid, v2: Ventetid) => v1.ukerTotalt - v2.ukerTotalt);

    const ukerLavest = sortedVentetider[0].ukerTotalt;
    const ukerHoyest = sortedVentetider[sortedVentetider.length - 1].ukerTotalt;

    if (ukerLavest === ukerHoyest) {
      return `${ukerLavest} ${hentUkeString(resources, ukerLavest)}`;
    }

    return `${ukerLavest} - ${ukerHoyest} ${resources.tabellUker}`;
  }
  return '-';
};

export const hentHelseregionString = (helseregion: string | null, resources: HNVelgBehandlingsstedFrontend): string => {
  switch (helseregion) {
    case HelseRegion.Unknown:
      return '';
    case HelseRegion.HelseMidt:
      return resources.filterHelseregionHelseMidt;
    case HelseRegion.HelseNord:
      return resources.filterHelseregionHelseNord;
    case HelseRegion.HelseVest:
      return resources.filterHelseregionHelseVest;
    case HelseRegion.HelseSorOst:
      return resources.filterHelseregionHelseSorOst;
    case HelseRegion.Helfo:
      return resources.filterHelseregionHelfo;
    default:
      return '';
  }
};

export const hentHelseregionAvtaleString = (helseregion: string | null, resources: HNVelgBehandlingsstedFrontend): string => {
  switch (helseregion) {
    case HelseRegion.Unknown:
      return '';
    case HelseRegion.HelseMidt:
      return resources.ventetiderForBehandlingAvtaleMedHelseMidt;
    case HelseRegion.HelseNord:
      return resources.ventetiderForBehandlingAvtaleMedHelseNord;
    case HelseRegion.HelseVest:
      return resources.ventetiderForBehandlingAvtaleMedHelseVest;
    case HelseRegion.HelseSorOst:
      return resources.ventetiderForBehandlingAvtaleMedHelseSorOst;
    default:
      return '';
  }
};
