export enum ColumnNames {
  Behandlingssted = 'behandlingssted',
  Behandlingstilbud = 'behandlingstilbud',
  Ventetid = 'ventetid',
  Antall = 'antall',
  Behandling = 'behandling',
}

export enum Urls {
  Behandlinger = '/behandlinger',
  VentetiderForBehandling = '/behandlinger/ventetider-for',
  Behandlingssteder = '/behandlingssteder',
  SlikVelgerDu = '/rettigheter/fritt-behandlingsvalg/slik-velger-du-steg-for-steg',
}

export enum HelsenorgeUrls {
  Avtalespesialister = '/behandlere/avtalespesialist/',
}

export enum HelseRegion {
  HelseMidt = 'HelseMidt',
  HelseNord = 'HelseNord',
  HelseSorOst = 'HelseSorOst',
  HelseVest = 'HelseVest',
  Helfo = 'Helfo',
  Unknown = 'Unknown',
}
