import React from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { HelsenorgeUrls, Urls } from '../types/ExtendEnums';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import AnchorLink from '@helsenorge/designsystem-react/components/AnchorLink';
import Title from '@helsenorge/designsystem-react/components/Title';
import designsystemtypography from '@helsenorge/designsystem-react/scss/typography.module.scss';

import { Suggestion } from '@helsenorge/autosuggest/components/autosuggest';
import PageHeader from '@helsenorge/core-framework/page-header';
import PageLoader from '@helsenorge/core-framework/page-loader/page-loader';
import { LanguageLocales } from '@helsenorge/core-utils/constants/languages';
import { getSidetittelId } from '@helsenorge/framework-utils/hn-page';
import { getHelsenorgeUrl } from '@helsenorge/framework-utils/hn-proxy-service';
import { getResourcesFromState } from '@helsenorge/framework-utils/resources';

import AutosuggestComponent from '../shared/autosuggest';
import { hentSokBehandlinger, getSokBehandlingerLoading, getSokBehandlingerSuggestions } from '../shared/autosuggest/sok-behandlinger';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { HN_VELG_BEHANDLINGSSTED_FRONTEND } from '../utils/resources-project';

import './styles.scss';

const Forside: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const resources = useAppSelector(
    state => getResourcesFromState(state, HN_VELG_BEHANDLINGSSTED_FRONTEND, LanguageLocales.NORWEGIAN) as HNVelgBehandlingsstedFrontend
  );
  const loadingSokBehandlinger = useAppSelector(getSokBehandlingerLoading);
  const allSuggestions = useAppSelector(getSokBehandlingerSuggestions);

  const handleSuggestionSelected = (suggestion: Suggestion): void => {
    navigate(`${Urls.VentetiderForBehandling}?bid=${suggestion.value}`);
  };

  React.useEffect(() => {
    dispatch(hentSokBehandlinger());
  }, []);

  return (
    <PageLoader resources={resources} loading={loadingSokBehandlinger} labelId={getSidetittelId()}>
      <div className="container">
        <PageHeader
          title={resources.forsideTittel}
          description={resources.forsideIngress}
          tjenesteNavn="velg-behandlingssted"
          showHjelpetrigger
        />
      </div>

      <div className="vbs-forside__content-wrapper">
        <div className="container">
          <Title htmlMarkup="h2" appearance={'title3'} margin={1}>
            {resources.forsideBehandlingerOgUndersokelser}
          </Title>

          <AutosuggestComponent
            label={resources.forsideSokLabel}
            allSuggestions={allSuggestions ? allSuggestions : []}
            handleSuggestionSelected={handleSuggestionSelected}
          />

          <ul className={`vbs-forside__lenker ${designsystemtypography['anchorlink-wrapper']}`}>
            <li>
              <Link to={Urls.Behandlinger}>{resources.forsideLinkBehandlinger}</Link>
            </li>
            <li>
              <Link to={Urls.Behandlingssteder}>{resources.forsideLinkBehandlingssteder}</Link>
            </li>
            <li>
              <AnchorLink href={`${getHelsenorgeUrl()}${HelsenorgeUrls.Avtalespesialister}`}>
                {resources.forsideLinkAvtalespesialister}
              </AnchorLink>
            </li>
          </ul>
        </div>
      </div>
    </PageLoader>
  );
};

export default Forside;
