import { createReducer, ThunkDispatch } from '@reduxjs/toolkit';

import {
  VentetiderForBehandlingResponse,
  VentetiderForbehandling,
  VentetiderForBehandlingBehandlingssted,
} from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import { get } from '@helsenorge/framework-utils/hn-proxy-service';
import { error } from '@helsenorge/framework-utils/logger';

import { RootState } from '../store';
import { GlobalAction } from '../store/reducer';

/** ACTION TYPES */

const VENTETIDER_FOR_BEHANDLING_LOAD = 'velg-behandlingssted/VENTETIDER_FOR_BEHANDLING_LOAD';
const VENTETIDER_FOR_BEHANDLING_LOAD_SUCCESS = 'velg-behandlingssted/VENTETIDER_FOR_BEHANDLING_LOAD_SUCCESS';
const VENTETIDER_FOR_BEHANDLING_LOAD_FAIL = 'velg-behandlingssted/VENTETIDER_FOR_BEHANDLING_LOAD_FAIL';
const VENTETIDER_FOR_BEHANDLING_RESET_ERROR = 'velg-behandlingssted/VENTETIDER_FOR_BEHANDLING_RESET_ERROR';

/** ACTIONS */

interface VentetiderForBehandlingLoadAction {
  type: typeof VENTETIDER_FOR_BEHANDLING_LOAD;
}

interface VentetiderForBehandlingLoadSuccessAction {
  type: typeof VENTETIDER_FOR_BEHANDLING_LOAD_SUCCESS;
  bid: string;
  ventetiderForBehandling: VentetiderForbehandling | null;
}

interface VentetiderForBehandlingLoadFailAction {
  type: typeof VENTETIDER_FOR_BEHANDLING_LOAD_FAIL;
  error: string;
}

interface VentetiderForBehandlingResetErrorAction {
  type: typeof VENTETIDER_FOR_BEHANDLING_RESET_ERROR;
}

export type VentetiderForBehandlingAction =
  | VentetiderForBehandlingLoadAction
  | VentetiderForBehandlingLoadSuccessAction
  | VentetiderForBehandlingLoadFailAction
  | VentetiderForBehandlingResetErrorAction;

/** ACTION CREATORS */

export const loadVentetiderForBehandling = (): VentetiderForBehandlingLoadAction => {
  return { type: VENTETIDER_FOR_BEHANDLING_LOAD };
};

export const successLoadVentetiderForBehandling = (
  bid: string,
  ventetiderForBehandling: VentetiderForbehandling | null
): VentetiderForBehandlingLoadSuccessAction => {
  return { type: VENTETIDER_FOR_BEHANDLING_LOAD_SUCCESS, bid: bid, ventetiderForBehandling: ventetiderForBehandling };
};

export const failedVentetiderForBehandling = (error: string): VentetiderForBehandlingLoadFailAction => {
  return { type: VENTETIDER_FOR_BEHANDLING_LOAD_FAIL, error: error };
};

export const resetErrorVentetiderForBehandling = (): VentetiderForBehandlingResetErrorAction => {
  return { type: VENTETIDER_FOR_BEHANDLING_RESET_ERROR };
};

/** REDUCER */

export interface VentetiderForBehandlingByBid {
  navn: string | null;
  harAntall: boolean;
  behandlingssteder: Array<VentetiderForBehandlingBehandlingssted> | null;
  kommentar: string | null;
}

export type VentetiderForBehandlingState = {
  loading: boolean;
  ventetiderForBehandlingByBid: { [bid: string]: VentetiderForBehandlingByBid };
  error?: string;
};

export const initialState = { loading: false, ventetiderForBehandlingByBid: {} };

const ventetiderForBehandling = createReducer<VentetiderForBehandlingState>(initialState, builder => {
  builder
    .addCase(VENTETIDER_FOR_BEHANDLING_LOAD, state => {
      state.loading = true;
    })
    .addCase(VENTETIDER_FOR_BEHANDLING_LOAD_SUCCESS, (state, action: VentetiderForBehandlingLoadSuccessAction) => {
      state.loading = false;

      if (action.ventetiderForBehandling) {
        state.ventetiderForBehandlingByBid[action.bid] = {
          navn: action.ventetiderForBehandling.navn,
          harAntall: action.ventetiderForBehandling.harAntall,
          behandlingssteder: action.ventetiderForBehandling.behandlingssteder,
          kommentar: action.ventetiderForBehandling.kommentar,
        };
      }
    })
    .addCase(VENTETIDER_FOR_BEHANDLING_LOAD_FAIL, (state, action: VentetiderForBehandlingLoadFailAction) => {
      state.loading = false;
      state.error = action.error;
    })
    .addCase(VENTETIDER_FOR_BEHANDLING_RESET_ERROR, state => {
      state.error = undefined;
    });
});

/** STATE SELECTORS */

export function getVentetiderForBehandlingState(state: RootState): VentetiderForBehandlingState {
  if (!state.ventetiderForBehandling) {
    error('the ventetiderForBehandling state is missing in this reducer');
  }
  return state.ventetiderForBehandling;
}

export function getVentetiderForBehandlingLoading(state: RootState): boolean {
  return getVentetiderForBehandlingState(state).loading;
}

export function getVentetiderForBehandlingError(state: RootState): string | undefined {
  return getVentetiderForBehandlingState(state).error;
}

export function getVentetiderForBehandlingByBid(state: RootState, bid: string): VentetiderForBehandlingByBid | undefined {
  return getVentetiderForBehandlingState(state).ventetiderForBehandlingByBid[bid];
}

/** API */

function shouldHenteVentetiderForBehandling(state: RootState, bid: string): boolean {
  return !getVentetiderForBehandlingByBid(state, bid) && !getVentetiderForBehandlingLoading(state);
}

export function hentVentetiderForBehandling(bid: string, resources: HNVelgBehandlingsstedFrontend) {
  return (dispatch: ThunkDispatch<RootState, void, GlobalAction>, getState: () => RootState): Promise<void> | undefined => {
    const state = getState();

    if (!parseInt(bid, 10)) {
      dispatch(failedVentetiderForBehandling(`${resources.tekniskFeilVentetiderForBehandling} ${bid}`));
    } else if (shouldHenteVentetiderForBehandling(state, bid)) {
      dispatch(loadVentetiderForBehandling());

      return get<VentetiderForBehandlingResponse>('VelgBehandlingssted', 'api/v1/VentetiderForBehandling', { behandlingId: bid })
        .then((data: VentetiderForBehandlingResponse) => {
          dispatch(successLoadVentetiderForBehandling(bid, data.ventetiderForBehandling));
        })
        .catch(() => {
          dispatch(failedVentetiderForBehandling(`${resources.tekniskFeilVentetiderForBehandling} ${bid}`));
        });
    }
  };
}

export default ventetiderForBehandling;
