import React from 'react';

import { Behandlingstilbud } from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';

import { Cell } from '@helsenorge/data-table/components/table';

import { TabellData } from './table-helper';

interface Props {
  rowData?: TabellData;
}

export default class BehandlingstilbudCell extends React.PureComponent<Props> {
  render(): JSX.Element | null {
    const { rowData, ...other } = this.props;

    return (
      <Cell {...other}>
        {rowData && (
          <>
            {rowData.behandlingsstedData.behandlingstilbud &&
              rowData.behandlingsstedData.behandlingstilbud.map((behandlingstilbud: Behandlingstilbud, index: number) => {
                const showComma =
                  rowData.behandlingsstedData.behandlingstilbud && index < rowData.behandlingsstedData.behandlingstilbud.length - 1;

                return (
                  <span key={index}>
                    {behandlingstilbud.navn}
                    <span className="vbs-main__subtitle"> {`(${behandlingstilbud.antall})`}</span>
                    {showComma && ', '}
                  </span>
                );
              })}
          </>
        )}
      </Cell>
    );
  }
}
