import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import { FilterResources } from '@helsenorge/filter/components/filter';

import { FilterValue } from '../behandlingssteder/filters-helper';

export const getFiltersStrings = (resources: HNVelgBehandlingsstedFrontend): FilterResources => {
  return {
    filterTitle: resources.filterTitle,
    hideFilter: resources.filterHide,
    showFilter: resources.filterShow,
    resetFilters: resources.filterReset,
    selectedFilters: resources.filterSelected,
    numResults: resources.filterResultsNum,
    showResults: resources.filterResultsShow,
    filterOn: resources.filterOn,
    useFilter: resources.filterUse,
    filterHeading: resources.filterHeading,
    cancel: resources.filterCancel,
    filterMobileNavigateBack: resources.filterMobileBack,
    chosen: resources.filterChosen,
    searchButtonText: resources.filterSearchText,
    hideSecondaryFilters: resources.filterSecondaryHide,
    showSecondaryFilters: resources.filterSecondaryShow,
    toggleFilterAndSort: resources.filterToggleFilterAndSort,
  };
};

export const sortFilterValues = (filterValues: Array<FilterValue>): Array<FilterValue> => {
  return filterValues.sort((fv1: FilterValue, fv2: FilterValue) => {
    return fv1.displayName && fv2.displayName ? fv1.displayName.localeCompare(fv2.displayName, 'nb') : 0;
  });
};
