import React from 'react';

import { Ventetid } from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import { hentUkeString } from '../utils/strings';

interface Props {
  resources: HNVelgBehandlingsstedFrontend;
  ventetid: Ventetid;
  lineBreak?: boolean;
}

const VentetidComponent: React.FunctionComponent<Props> = ({ ventetid, resources, lineBreak }: Props) => {
  let ventetidString = '';

  if (ventetid.uker > 0) {
    ventetidString = `${ventetid.uker} ${hentUkeString(resources, ventetid.uker)} ${resources.ventetiderTil} ${ventetid.type}`;

    if (ventetid.ukerTotalt > ventetid.uker) {
      ventetidString += ` (${ventetid.ukerTotalt} ${resources.ventetiderForBehandlingUkerTotalt})`;
    }
  } else {
    ventetidString = `${resources.ikkeOppdatertVentetid} ${ventetid.type}`;
  }

  return (
    <React.Fragment>
      <span>{ventetidString}</span>
      {lineBreak && <br />}
    </React.Fragment>
  );
};

export default VentetidComponent;
