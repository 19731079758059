import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import { LanguageLocales } from '@helsenorge/core-utils/constants/languages';
import { getResourcesFromState } from '@helsenorge/framework-utils/resources';

import {
  hentVentetiderForBehandling,
  getVentetiderForBehandlingByBid,
  getVentetiderForBehandlingError,
  getVentetiderForBehandlingLoading,
  resetErrorVentetiderForBehandling,
} from './ventetider-for-behandling';
import VentetiderForBehandlingComponent from './ventetider-for-behandling-component';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { HN_VELG_BEHANDLINGSSTED_FRONTEND } from '../utils/resources-project';

const VentetiderForBehandlingContainer: React.FunctionComponent = () => {
  const [searchparams] = useSearchParams();
  const dispatch = useAppDispatch();

  const resources = useAppSelector(
    state => getResourcesFromState(state, HN_VELG_BEHANDLINGSSTED_FRONTEND, LanguageLocales.NORWEGIAN) as HNVelgBehandlingsstedFrontend
  );

  const ventetiderForBehandlingByBid = useAppSelector(state => getVentetiderForBehandlingByBid(state, searchparams.get('bid')));
  const loading = useAppSelector(getVentetiderForBehandlingLoading);
  const error = useAppSelector(getVentetiderForBehandlingError);

  React.useEffect(() => {
    dispatch(hentVentetiderForBehandling(searchparams.get('bid'), resources));

    return (): void => {
      dispatch(resetErrorVentetiderForBehandling());
    };
  }, []);

  return (
    <VentetiderForBehandlingComponent
      resources={resources}
      loading={loading}
      error={error}
      ventetiderForBehandlingByBid={ventetiderForBehandlingByBid}
    />
  );
};

export default VentetiderForBehandlingContainer;
