import React from 'react';

import { ColumnNames } from '../types/ExtendEnums';
import { Behandlingsgruppe, Ventetid } from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import { simpleConfig } from '@helsenorge/designsystem-react/components/Table';

import { Table, Column } from '@helsenorge/data-table/components/table';

import BehandlingCell from './behandling-cell';
import {
  getBehandlingerTabellData,
  BehandlingTabellData,
  getBehandlingTabellClass,
  getBehandlingsstederBehandlingTableCaption,
  getVentetidString,
  sortVentetidTypes,
} from './table-helper';

interface Props {
  resources: HNVelgBehandlingsstedFrontend;
  behandlingsGruppe: Behandlingsgruppe;
  behandlingssted: string;
  index: number;
}

const BehandlingerTable: React.FunctionComponent<Props> = ({ resources, behandlingsGruppe, behandlingssted, index }: Props) => {
  const tabellData: Array<BehandlingTabellData> = getBehandlingerTabellData(resources, behandlingsGruppe.behandlinger);
  const caption = getBehandlingsstederBehandlingTableCaption(
    resources,
    behandlingssted,
    behandlingsGruppe.navn ? behandlingsGruppe.navn : ''
  );

  const ventetidTypes = [
    ...new Set(
      tabellData
        .map(o => o.behandlingData)
        .map(o => o.ventetider || ([] as Ventetid[]))
        .reduce((a, b) => a.concat(b)) // Bytt ut .map() .reduce() med flatmap når det kommer støtte for dette. Det fungerer nå i Chrome
        .map(o => o.type || '')
    ),
  ];

  ventetidTypes.sort(sortVentetidTypes);

  const tabellDataMedVentetider = tabellData.map(rowData => ({
    ...rowData,
    ...Object.fromEntries(
      ventetidTypes.map(key => [
        key,
        getVentetidString(
          resources,
          rowData.behandlingData?.ventetider?.find(v => v.type === key)
        ),
      ])
    ),
  }));

  return (
    <Table
      id={`${behandlingssted}-behandling-table-${index}`}
      data={tabellDataMedVentetider}
      tableClassNames={`vbs-behandlingssteder__behandling-tabell ${getBehandlingTabellClass(
        ventetidTypes.length + (behandlingsGruppe.harAntall ? 1 : 0)
      )}`}
      initialSortColumnKey={ColumnNames.Behandling}
      initialSortDirIsAsc={true}
      sortColumnKey={ColumnNames.Behandling}
      sortDirIsAsc={true}
      useNumericSort
      caption={caption}
      breakpointConfig={simpleConfig}
    >
      <Column
        name={ColumnNames.Behandling}
        title={resources.behandlingsstederTabellBehandling}
        sortable
        cell={<BehandlingCell columnName={ColumnNames.Behandling} resources={resources} />}
      />
      {ventetidTypes.map((ventetidType, i) => (
        <Column
          key={i}
          name={ventetidType}
          title={`${resources.behandlingsstederTabellVentetiderTil} ${ventetidType}`}
          sortable
          cell={<BehandlingCell columnName={ventetidType} resources={resources} />}
        />
      ))}
      {behandlingsGruppe.harAntall && (
        <Column
          name={ColumnNames.Antall}
          title={resources.behandlingsstederTabellAntall}
          sortable
          cell={<BehandlingCell columnName={ColumnNames.Antall} resources={resources} />}
        />
      )}
    </Table>
  );
};

export default BehandlingerTable;
