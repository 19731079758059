import { createReducer, createSelector, ThunkDispatch } from '@reduxjs/toolkit';

import { SokBehandlingerResponse, Behandling } from '../../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';

import { Suggestion } from '@helsenorge/autosuggest/components/autosuggest';
import { get } from '@helsenorge/framework-utils/hn-proxy-service';
import { error } from '@helsenorge/framework-utils/logger';

import { RootState } from '../../store';
import { GlobalAction } from '../../store/reducer';

/** ACTION TYPES */

const SOK_BEHANDLINGER_LOAD = 'velg-behandlingssted/SOK_BEHANDLINGER_LOAD';
const SOK_BEHANDLINGER_LOAD_SUCCESS = 'velg-behandlingssted/SOK_BEHANDLINGER_LOAD_SUCCESS';
const SOK_BEHANDLINGER_LOAD_FAIL = 'velg-behandlingssted/SOK_BEHANDLINGER_LOAD_FAIL';

/** ACTIONS */

interface SokBehandlingerLoadAction {
  type: typeof SOK_BEHANDLINGER_LOAD;
}

interface SokBehandlingerLoadSuccessAction {
  type: typeof SOK_BEHANDLINGER_LOAD_SUCCESS;
  behandlinger: Array<Behandling> | null;
}

interface SokBehandlingerLoadFailAction {
  type: typeof SOK_BEHANDLINGER_LOAD_FAIL;
}

export type SokBehandlingerAction = SokBehandlingerLoadAction | SokBehandlingerLoadSuccessAction | SokBehandlingerLoadFailAction;

/** ACTION CREATORS */

export const loadSokBehandlinger = (): SokBehandlingerLoadAction => {
  return { type: SOK_BEHANDLINGER_LOAD };
};

export const successSokBehandlinger = (behandlinger: Array<Behandling> | null): SokBehandlingerLoadSuccessAction => {
  return { type: SOK_BEHANDLINGER_LOAD_SUCCESS, behandlinger: behandlinger };
};

export const failedSokBehandlinger = (): SokBehandlingerLoadFailAction => {
  return { type: SOK_BEHANDLINGER_LOAD_FAIL };
};

/** REDUCER */

export type SokBehandlingerState = {
  loading: boolean;
  behandlinger?: Array<Behandling> | null;
};

export const initialState = { loading: false };

const sokBehandlinger = createReducer<SokBehandlingerState>(initialState, builder => {
  builder
    .addCase(SOK_BEHANDLINGER_LOAD, state => {
      state.loading = true;
    })
    .addCase(SOK_BEHANDLINGER_LOAD_SUCCESS, (state, action: SokBehandlingerLoadSuccessAction) => {
      state.loading = false;
      state.behandlinger = action.behandlinger;
    })
    .addCase(SOK_BEHANDLINGER_LOAD_FAIL, state => {
      state.loading = false;
    });
});

/** STATE SELECTORS */

export function getSokBehandlingerState(state: RootState): SokBehandlingerState {
  if (!state.sokBehadlinger) {
    error('the sokBehandlinger state is missing in this reducer');
  }
  return state.sokBehadlinger;
}

export function getSokBehandlingerLoading(state: RootState): boolean {
  return getSokBehandlingerState(state).loading;
}

export function getSokBehandlinger(state: RootState): Array<Behandling> | undefined | null {
  return getSokBehandlingerState(state).behandlinger;
}

const getBehandlinger = (state: RootState): Array<Behandling> => getSokBehandlingerState(state).behandlinger;

export const getSokBehandlingerSuggestions = createSelector([getBehandlinger], behandlinger => {
  let suggestions: Array<Suggestion> = [];

  if (behandlinger && behandlinger.length > 0) {
    suggestions = behandlinger.map(item => {
      return {
        label: item.navn ? item.navn : '',
        value: item.behandlingsId ? item.behandlingsId : '',
        optionalLabel: item.synonym ? `${item.synonym}` : '',
      };
    });
  }

  return suggestions;
});

/** API */

export function hentSokBehandlinger() {
  return (dispatch: ThunkDispatch<RootState, void, GlobalAction>, getState: () => RootState): Promise<void> | undefined => {
    const state = getState();

    if (!getSokBehandlingerLoading(state) && !getSokBehandlinger(state)) {
      dispatch(loadSokBehandlinger());

      return get<SokBehandlingerResponse>('VelgBehandlingssted', 'api/v1/SokBehandlinger')
        .then((data: SokBehandlingerResponse) => {
          dispatch(successSokBehandlinger(data.behandlinger));
        })
        .catch(() => {
          dispatch(failedSokBehandlinger());
        });
    }
  };
}

export default sokBehandlinger;
