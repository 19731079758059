import React from 'react';

import { Behandlingsgruppe } from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import ExpanderList from '@helsenorge/designsystem-react/components/ExpanderList';
import LazyIcon from '@helsenorge/designsystem-react/components/LazyIcon';
import Loader from '@helsenorge/designsystem-react/components/Loader';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';
import Title from '@helsenorge/designsystem-react/components/Title';

import { LanguageLocales } from '@helsenorge/core-utils/constants/languages';
import { getResourcesFromState } from '@helsenorge/framework-utils/resources';

import BehandlingerTable from './behandlinger-table';
import { TabellData, getTotalVentetidForBehandlingsgruppe } from './table-helper';
import {
  hentVentetiderForBehandlingssted,
  getVentetiderForBehandlingsstedLoading,
  getVentetiderForBehandlingsstedLoaded,
  getVentetiderForBehandlingsstedError,
  getVentetiderForBehandlingsstedBehandlingsgrupper,
} from './ventetider-for-behandlingssted';
import AdresseComponent from '../shared/adresse';
import TelefonComponent from '../shared/telefon';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { behandlingSvgIkonMapper } from '../utils/behandling-ikon-mapper';
import { HN_VELG_BEHANDLINGSSTED_FRONTEND } from '../utils/resources-project';

interface Props {
  rowData?: TabellData;
  expanded?: boolean;
}

const BehandlingsstedExpanded: React.FunctionComponent<Props> = ({ rowData, expanded }: Props) => {
  const dispatch = useAppDispatch();
  const resources = useAppSelector(
    state => getResourcesFromState(state, HN_VELG_BEHANDLINGSSTED_FRONTEND, LanguageLocales.NORWEGIAN) as HNVelgBehandlingsstedFrontend
  );

  const orgnr = rowData?.orgnr;

  const loading = useAppSelector(state => getVentetiderForBehandlingsstedLoading(state, orgnr));
  const loaded = useAppSelector(state => getVentetiderForBehandlingsstedLoaded(state, orgnr));
  const error = useAppSelector(state => getVentetiderForBehandlingsstedError(state, orgnr));
  const behandlingsGrupper = useAppSelector(state => getVentetiderForBehandlingsstedBehandlingsgrupper(state, orgnr));
  const behandlingsstedData = rowData?.behandlingsstedData;

  React.useEffect(() => {
    if (expanded && orgnr) {
      dispatch(hentVentetiderForBehandlingssted(orgnr, resources));
    }
  }, [expanded, orgnr]);

  if (!orgnr) {
    return null;
  }

  if (loading) {
    return (
      <div className="vbs-behandlingssteder__loader-wrapper">
        <Loader size="medium" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="vbs-behandlingssteder__error-wrapper">
        <NotificationPanel variant="error" label={error} />
      </div>
    );
  }

  return loaded && behandlingsstedData ? (
    <>
      <div className="vbs-main__expanded-inner-content">
        <Title htmlMarkup="h2" appearance={'title2'} margin={1}>
          {behandlingsstedData.navnBedriftsregister}
        </Title>

        <p>{`${resources.behandlingsstederOrgnr} ${behandlingsstedData.orgnr}`}</p>

        {!!behandlingsstedData.besoksAdresse && !!behandlingsstedData.besoksAdresse.gateadresse && (
          <>
            <Title htmlMarkup="h3" appearance={'title4'} margin={0.5}>
              {resources.tabellBesoksadresse}
            </Title>
            <AdresseComponent adresse={behandlingsstedData.besoksAdresse} />
          </>
        )}

        {!!behandlingsstedData.telefon && (
          <>
            <Title htmlMarkup="h3" appearance={'title4'} margin={0.5}>
              {resources.tabellTelefon}
            </Title>
            <TelefonComponent telefon={behandlingsstedData.telefon} />
          </>
        )}

        <br />
      </div>

      {behandlingsGrupper && (
        <ExpanderList color="neutral">
          {behandlingsGrupper.map((behandlingsGruppe: Behandlingsgruppe, index: number) => {
            return (
              <ExpanderList.Expander
                key={index}
                icon={<LazyIcon iconName={behandlingSvgIkonMapper(behandlingsGruppe.kode)} />}
                title={
                  <div>
                    <span>{`${getTotalVentetidForBehandlingsgruppe(
                      resources,
                      behandlingsGruppe.ventetidMin,
                      behandlingsGruppe.ventetidMax
                    )} ${behandlingsGruppe.navn} `}</span>
                    <span className="vbs-main__subtitle">{`(${behandlingsGruppe.behandlinger?.length})`}</span>
                  </div>
                }
              >
                <BehandlingerTable
                  behandlingsGruppe={behandlingsGruppe}
                  resources={resources}
                  behandlingssted={behandlingsstedData.navnBedriftsregister ? behandlingsstedData.navnBedriftsregister : ''}
                  index={index}
                />
              </ExpanderList.Expander>
            );
          })}
        </ExpanderList>
      )}
    </>
  ) : null;
};

export default BehandlingsstedExpanded;
