import { combineReducers } from 'redux';

import hjelpeskuff from '@helsenorge/core-framework/prosesshjelp/hjelpeskuff/hjelpeskuff-state';
import { cmsTjenesteTekster } from '@helsenorge/framework-utils/cms-tjeneste-tekster';
import { resources } from '@helsenorge/framework-utils/resources';

import behandlinger, { BehandlingerAction } from '../behandlinger/behandlinger';
import behandlingssteder, { BehandlingsstederAction } from '../behandlingssteder/behandlingssteder';
import ventetiderForBehandlingssted, { VentetiderForBehandlingsstedAction } from '../behandlingssteder/ventetider-for-behandlingssted';
import sokBehadlinger, { SokBehandlingerAction } from '../shared/autosuggest/sok-behandlinger';
import ventetiderForBehandling, { VentetiderForBehandlingAction } from '../ventetider-for-behandling/ventetider-for-behandling';

const rootReducer = combineReducers({
  resources,
  hjelpeskuff,
  behandlinger,
  ventetiderForBehandling,
  behandlingssteder,
  ventetiderForBehandlingssted,
  sokBehadlinger,
  cmsTjenesteTekster,
});

export type GlobalAction =
  | BehandlingerAction
  | VentetiderForBehandlingAction
  | BehandlingsstederAction
  | VentetiderForBehandlingsstedAction
  | SokBehandlingerAction;

export default rootReducer;
