import React from 'react';

import AnchorLink from '@helsenorge/designsystem-react/components/AnchorLink';

interface Props {
  telefon: string;
}

const TelefonComponent: React.FunctionComponent<Props> = ({ telefon }: Props) => {
  return (
    <p>
      <AnchorLink href={`tel:${telefon}`}>{telefon}</AnchorLink>
    </p>
  );
};

export default TelefonComponent;
