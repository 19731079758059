import React from 'react';

import { IVentetid } from '../types/ExtendEntities';
import { VentetiderForBehandlingBehandlingssted } from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import { Cell } from '@helsenorge/data-table/components/table';

import { skalVisesIVentetidCell } from './helper';
import { hentVentetidString } from '../utils/strings';

export interface RowData {
  behandlingsstedData: VentetiderForBehandlingBehandlingssted;
}

interface Props {
  resources: HNVelgBehandlingsstedFrontend;
  rowData?: RowData;
}

export default class VentetidCell extends React.PureComponent<Props> {
  render(): JSX.Element | null {
    const { resources, rowData, ...other } = this.props;

    const ikkeOppdatert =
      !!rowData &&
      !!rowData.behandlingsstedData.ventetider &&
      rowData.behandlingsstedData.ventetider.length > 0 &&
      rowData.behandlingsstedData.ventetider.filter(
        (ventetid: IVentetid) =>
          ventetid.ukerTotalt > 0 && (ventetid.skalVisesIVentetidCell || ventetid.skalVisesIVentetidCell === undefined)
      ).length === 0;

    return (
      <Cell {...other}>
        {rowData && (
          <span className={!ikkeOppdatert ? 'vbs-ventetider-for-behandling__uker' : ''}>
            {hentVentetidString(resources, rowData.behandlingsstedData.ventetider?.filter(skalVisesIVentetidCell))}
          </span>
        )}
      </Cell>
    );
  }
}
