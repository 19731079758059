import { createReducer, ThunkDispatch } from '@reduxjs/toolkit';

import {
  BehandlingsstederResponse,
  BehandlingsstederBehandlingssted,
} from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import { get } from '@helsenorge/framework-utils/hn-proxy-service';
import { error } from '@helsenorge/framework-utils/logger';

import { RootState } from '../store';
import { GlobalAction } from '../store/reducer';

/** ACTION TYPES */

const BEHANDLINGSSTEDER_LOAD = 'velg-behandlingssted/BEHANDLINGSSTEDER_LOAD';
const BEHANDLINGSSTEDER_LOAD_SUCCESS = 'velg-behandlingssted/BEHANDLINGSSTEDER_LOAD_SUCCESS';
const BEHANDLINGSSTEDER_LOAD_FAIL = 'velg-behandlingssted/BEHANDLINGSSTEDER_LOAD_FAIL';

/** ACTIONS */

interface BehandlingsstederLoadAction {
  type: typeof BEHANDLINGSSTEDER_LOAD;
}

interface BehandlingsstederLoadSuccessAction {
  type: typeof BEHANDLINGSSTEDER_LOAD_SUCCESS;
  behandlingssteder: Array<BehandlingsstederBehandlingssted> | null;
}

interface BehandlingsstederLoadFailAction {
  type: typeof BEHANDLINGSSTEDER_LOAD_FAIL;
  error: string;
}

export type BehandlingsstederAction = BehandlingsstederLoadAction | BehandlingsstederLoadSuccessAction | BehandlingsstederLoadFailAction;

/** ACTION CREATORS */

export const loadBehandlingssteder = (): BehandlingsstederLoadAction => {
  return { type: BEHANDLINGSSTEDER_LOAD };
};

export const successBehandlingssteder = (
  behandlingssteder: Array<BehandlingsstederBehandlingssted> | null
): BehandlingsstederLoadSuccessAction => {
  return { type: BEHANDLINGSSTEDER_LOAD_SUCCESS, behandlingssteder: behandlingssteder };
};

export const failedBehandlingssteder = (error: string): BehandlingsstederLoadFailAction => {
  return { type: BEHANDLINGSSTEDER_LOAD_FAIL, error: error };
};

/** REDUCER */

export type BehandlingsstederState = {
  loading: boolean;
  error?: string;
  behandlingssteder?: Array<BehandlingsstederBehandlingssted> | null;
};

export const initialState = { loading: false };

const behandlingssteder = createReducer<BehandlingsstederState>(initialState, builder => {
  builder
    .addCase(BEHANDLINGSSTEDER_LOAD, state => {
      state.loading = true;
    })
    .addCase(BEHANDLINGSSTEDER_LOAD_SUCCESS, (state, action: BehandlingsstederLoadSuccessAction) => {
      state.loading = false;
      state.behandlingssteder = action.behandlingssteder;
    })
    .addCase(BEHANDLINGSSTEDER_LOAD_FAIL, (state, action: BehandlingsstederLoadFailAction) => {
      state.loading = false;
      state.error = action.error;
    });
});

/** STATE SELECTORS */

export function getBehandlingsstederState(state: RootState): BehandlingsstederState {
  if (!state.behandlingssteder) {
    error('the behandlingssteder state is missing in this reducer');
  }
  return state.behandlingssteder;
}

export function getBehandlingsstederLoading(state: RootState): boolean {
  return getBehandlingsstederState(state).loading;
}

export function getBehandlingsstederError(state: RootState): string | undefined {
  return getBehandlingsstederState(state).error;
}

export function getBehandlingssteder(state: RootState): Array<BehandlingsstederBehandlingssted> | undefined | null {
  return getBehandlingsstederState(state).behandlingssteder;
}

/** API */

function shouldHenteBehandlingssteder(state: RootState): boolean {
  return !getBehandlingsstederLoading(state) && !getBehandlingssteder(state);
}

export function hentBehandlingssteder(resources: HNVelgBehandlingsstedFrontend) {
  return (dispatch: ThunkDispatch<RootState, void, GlobalAction>, getState: () => RootState): Promise<void> | undefined => {
    if (shouldHenteBehandlingssteder(getState())) {
      dispatch(loadBehandlingssteder());

      return get<BehandlingsstederResponse>('VelgBehandlingssted', 'api/v1/Behandlingssteder')
        .then((data: BehandlingsstederResponse) => {
          dispatch(successBehandlingssteder(data.behandlingssteder));
        })
        .catch(() => {
          dispatch(failedBehandlingssteder(resources.tekniskFeilBehandlingssteder));
        });
    }
  };
}

export default behandlingssteder;
