import { IconName } from '@helsenorge/designsystem-react/components/Icons/IconNames';

export const behandlingSvgIkonMapper = (kode: string | null): IconName => {
  switch (kode) {
    case 'FY01':
      return 'ElderlyPerson';
    case 'FY02':
      return 'FirstAidKit';
    case 'FY03':
      return 'PersonInXRayMachine';
    case 'FY04':
      return 'Breasts';
    case 'FY05':
      return 'DigestiveSystem';
    case 'FY06':
      return 'MaleDoctor';
    case 'FY07':
      return 'Pregnant';
    case 'FY08':
      return 'Heart';
    case 'FY09':
      return 'Hormone';
    case 'FY10':
      return 'Skin';
    case 'FY11':
      return 'PersonWithJaw';
    case 'FY12':
      return 'Female';
    case 'FY13':
      return 'MaleGenitalia';
    case 'FY14':
      return 'ArmFlexing';
    case 'FY15':
      return 'MuscleLeg';
    case 'FY16':
      return 'MuscleBack';
    case 'FY17':
      return 'PersonWithBrain';
    case 'FY18':
      return 'Kidney';
    case 'FY19':
      return 'JointPain';
    case 'FY20':
      return 'BandAid';
    case 'FY21':
      return 'MaleDoctorAndPerson';
    case 'FY22':
      return 'EarNoseThroat';
    case 'FY23':
      return 'Eye';
    case 'FY24':
      return 'Lungs';
    case 'FY25':
      return 'TeddyBear';
    case 'RE01':
      return 'Skeleton';
    case 'RE02':
      return 'JointPain';
    case 'RE03':
      return 'Amputation';
    case 'RE04':
      return 'PersonRelaxing';
    case 'RE05':
      return 'Cancer';
    case 'RE06':
      return 'Brain';
    case 'RE07':
      return 'PersonWithBrain';
    case 'RE08':
      return 'Heart';
    case 'RE09':
      return 'Lungs';
    case 'RE10':
      return 'ArmFlexing';
    case 'RE11':
      return 'PersonOverweight';
    case 'RE12':
      return 'PersonWithSenses';
    case 'RE13':
      return 'Hiker';
    case 'RE14':
      return 'PersonWorking';
    case 'RE15':
      return 'Puzzle';
    case 'REHAB':
      return 'PersonWorking';
    case 'REHA':
      return 'PersonWorking';
    case 'HABU':
      return 'ChildPlaying';
    case 'HAVO':
      return 'PersonWithCrutches';
    case 'TSB':
      return 'BeerAndPills';
    case 'VOP':
      return 'MentalHealthAdult';
    case 'BUP':
      return 'MentalHealthChild';
    default:
      return 'Minus';
  }
};
