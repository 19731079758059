import React from 'react';

import { ColumnNames } from '../types/ExtendEnums';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import Checkbox from '@helsenorge/designsystem-react/components/Checkbox';
import { simpleConfig } from '@helsenorge/designsystem-react/components/Table';

import PageHeader from '@helsenorge/core-framework/page-header';
import PageLoader from '@helsenorge/core-framework/page-loader/page-loader';
import { LanguageLocales } from '@helsenorge/core-utils/constants/languages';
import layoutChange from '@helsenorge/core-utils/hoc/layout-change';
import { Table, Column, ExpandableRow } from '@helsenorge/data-table/components/table';
import Filter, { DropDownFilterOption } from '@helsenorge/filter/components/filter';
import FilterSearch from '@helsenorge/filter/components/filter/filter-search';
import { useFilters } from '@helsenorge/filter/components/filter/useFilters';
import { getSidetittelId } from '@helsenorge/framework-utils/hn-page';
import { getResourcesFromState } from '@helsenorge/framework-utils/resources';

import BehandlingsstedExpanded from './behandlingssted-expanded';
import { hentBehandlingssteder, getBehandlingsstederLoading, getBehandlingsstederError, getBehandlingssteder } from './behandlingssteder';
import BehandlingstilbudCell from './behandlingstilbud-cell';
import { FilterObject, getInitialFilters } from './filters-helper';
import { getBehandlingsstederTabellData } from './table-helper';
import BehandlingsstedCell from '../shared/behandlingssted-cell';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getFiltersStrings } from '../utils/filters';
import { HN_VELG_BEHANDLINGSSTED_FRONTEND } from '../utils/resources-project';

import './styles.scss';

export type Filters = {
  [key: string]: string;
};

const Behandlingssteder: React.FC = () => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector(getBehandlingsstederLoading);
  const error = useAppSelector(getBehandlingsstederError);
  const behandlingssteder = useAppSelector(getBehandlingssteder);
  const resources = useAppSelector(
    state => getResourcesFromState(state, HN_VELG_BEHANDLINGSSTED_FRONTEND, LanguageLocales.NORWEGIAN) as HNVelgBehandlingsstedFrontend
  );

  const { filters, setDelayedFilter, updateFilters, activeFilters, searchQuery, setSearchQuery } = useFilters<Filters>();

  const [sortColumnKey, setSortColumnKey] = React.useState(ColumnNames.Behandlingssted);
  const [sortDirIsAsc, setSortDirIsAsc] = React.useState(true);
  const [filterData, setFilterData] = React.useState<Array<FilterObject>>([]);
  const [tabellData, setTabellData] = React.useState(getBehandlingsstederTabellData(resources, []));

  const onFilterSearch = (newSearchText: string): void => {
    if (behandlingssteder) {
      setSearchQuery(newSearchText);
    }
  };

  // Oppdaterer standard column sortering med nåværende, slik at ny rendring av komponentet ikke setter sortering tilbake til standard
  const onSortColumns = (key: ColumnNames, asc: boolean): void => {
    setSortColumnKey(key);
    setSortDirIsAsc(asc);
  };

  const filtersStrings = getFiltersStrings(resources);

  React.useEffect(() => {
    dispatch(hentBehandlingssteder(resources));
  }, []);

  React.useEffect(() => {
    if (behandlingssteder) {
      setFilterData(getInitialFilters(resources, behandlingssteder));
      setTabellData(getBehandlingsstederTabellData(resources, behandlingssteder));
    }
  }, [behandlingssteder]);

  React.useEffect(() => {
    if (behandlingssteder) {
      setTabellData(getBehandlingsstederTabellData(resources, behandlingssteder, activeFilters, searchQuery));
    }
  }, [filters, searchQuery]);

  const renderFilterDropdowns = (filterData: FilterObject[]): DropDownFilterOption[] => {
    return filterData.map((filter: FilterObject) => ({
      placeholder: filter?.name,
      label: filter?.name,
      type: 'dropdown',
      onClose: () => updateFilters(),
      children: filter?.values?.map(fv => (
        <Checkbox
          key={`${filter.name}-${fv.id}`}
          label={fv.displayName}
          value={fv.value}
          checked={filters && !!filters[filter.name + ';' + fv.value]}
          onChange={e => {
            setDelayedFilter(filter.name + ';' + fv.value, e.target.checked ? e.target.value : '');
          }}
        />
      )),
    }));
  };

  return (
    <PageLoader resources={resources} loading={loading} error={error} labelId={getSidetittelId()}>
      <PageHeader title={resources.behandlingsstederTittel} hjelpetekstId="allebehandlingssteder" showHjelpetrigger />
      <Filter
        activeFilters={activeFilters}
        isAlwaysOpen
        showFilters
        resources={filtersStrings}
        searchFilter={
          <FilterSearch
            onChange={(e?: React.ChangeEvent<HTMLInputElement>) => onFilterSearch(e?.target.value ?? '')}
            onSearch={() => onFilterSearch(searchQuery ?? '')}
          />
        }
        statusText={`${tabellData?.length} ${resources.behandlingsstederFilterUnit}`}
        filterOptions={renderFilterDropdowns(filterData)}
      />
      <Table
        id="behandlingssteder-table"
        data={tabellData}
        tableClassNames="vbs-behandlingssteder__behandlingssteder-tabell"
        initialSortColumnKey={sortColumnKey}
        initialSortDirIsAsc={sortDirIsAsc}
        sortColumnKey={sortColumnKey}
        sortDirIsAsc={sortDirIsAsc}
        onSortColumn={onSortColumns}
        showMoreButtonClasses="vbs-main__hent-flere"
        showMorePageSize={20}
        showMoreButtonText={resources.hentFlere}
        highlightText={searchQuery}
        emptyContentText={resources.tabellIngenTreff}
        caption={resources.behandlingsstederTabellCaption}
        useNumericSort
        noAnchorLinkWrapper
        breakpointConfig={simpleConfig}
      >
        <Column
          name={ColumnNames.Behandlingssted}
          title={resources.behandlingsstederTabellBehandlingssted}
          sortable
          cell={<BehandlingsstedCell />}
        />
        <Column
          name={ColumnNames.Behandlingstilbud}
          title={resources.behandlingsstederTabellBehandlingstilbud}
          sortable
          cell={<BehandlingstilbudCell />}
        />
        <Column name={ColumnNames.Ventetid} title={resources.behandlingsstederTabellVentetid} sortable />

        <ExpandableRow open tableId="behandlingssteder-table">
          <BehandlingsstedExpanded />
        </ExpandableRow>
      </Table>
    </PageLoader>
  );
};

export default layoutChange(Behandlingssteder);
