import { ColumnNames } from '../types/ExtendEnums';
import {
  BehandlingsstederBehandlingssted,
  Behandlingstilbud,
  BehandlingMedVentetider,
  Ventetid,
} from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import { ActiveFilter } from '@helsenorge/filter/components/filter/useFilters';

import { getFilteredBehandlingssteder } from './filters-helper';
import { hentUkeString, hentVentetidString } from '../utils/strings';

const ventetidSortOrder = [
  'utredning',
  'undersøkelse',
  'utredning/undersøkelse',
  'poliklinisk utredning/behandling',
  'undersøkelse/utredning',
  'dagbehandling',
  'poliklinisk gruppebehandling',
  'innleggelse',
  'innleggelse, individuelt tilbud',
  'innleggelse, gruppetilbud',
];

export const sortVentetidTypes = (a: string, b: string): number => ventetidSortOrder.indexOf(a) - ventetidSortOrder.indexOf(b);

export const sortVentetider = (a: Ventetid, b: Ventetid): number =>
  ventetidSortOrder.indexOf(a.type ?? '') - ventetidSortOrder.indexOf(b.type ?? '');

const getBehandlingsstilbudString = (behandlingstilbud: Array<Behandlingstilbud> | null): string => {
  let behandlingsstilbud = '';

  if (behandlingstilbud) {
    behandlingstilbud.forEach((tilbud: Behandlingstilbud) => {
      behandlingsstilbud += tilbud.navn + ' ' + tilbud.antall;
    });
  }

  return behandlingsstilbud;
};

export const getVentetidString = (resources: HNVelgBehandlingsstedFrontend, ventetid: Ventetid | null | undefined): string | null => {
  if (!ventetid || (ventetid.uker === null && ventetid.erGyldig === null)) {
    return resources.behandlingsstederTilbysIkke;
  } else if (ventetid?.erGyldig === false) {
    return resources.ikkeOppdatert;
  } else if (ventetid !== null && ventetid?.uker) {
    return `${ventetid.uker} ${hentUkeString(resources, ventetid?.uker)}`;
  }
  return null;
};

export const getVentetidElement = (resources: HNVelgBehandlingsstedFrontend, ventetid: Ventetid | null | undefined): JSX.Element | null => {
  const ventetidText = getVentetidString(resources, ventetid);

  if (!ventetidText) {
    return null;
  }

  return <span className={ventetid?.uker ? undefined : 'vbs-main__subtitle'}>{ventetidText}</span>;
};

export interface TabellData {
  behandlingsstedData: BehandlingsstederBehandlingssted;
  [ColumnNames.Behandlingssted]: string;
  orgnr: string;
  behandlingstilbud: string;
  ventetid: string;
}

export const getBehandlingsstederTabellData = (
  resources: HNVelgBehandlingsstedFrontend,
  behandlingssteder: Array<BehandlingsstederBehandlingssted>,
  activeFilters: Array<ActiveFilter> | undefined = undefined,
  searchText?: string
): Array<TabellData> => {
  return getFilteredBehandlingssteder(resources, behandlingssteder, activeFilters, searchText).map(
    (behandlingssted: BehandlingsstederBehandlingssted) => {
      let ventetider: Array<Ventetid> = [{ uker: behandlingssted.ukerMin, ukerTotalt: behandlingssted.ukerMin, type: '', erGyldig: true }];

      if (behandlingssted.ukerMax > behandlingssted.ukerMin) {
        ventetider = ventetider.concat({
          uker: behandlingssted.ukerMax,
          ukerTotalt: behandlingssted.ukerMax,
          type: '',
          erGyldig: true,
        });
      }

      return {
        behandlingsstedData: behandlingssted,
        orgnr: behandlingssted.orgnr.toString(),
        [ColumnNames.Behandlingssted]: behandlingssted.navn ? behandlingssted.navn : '',
        [ColumnNames.Behandlingstilbud]: getBehandlingsstilbudString(behandlingssted.behandlingstilbud),
        [ColumnNames.Ventetid]: `${hentVentetidString(resources, ventetider)}`,
      };
    }
  );
};

export interface BehandlingTabellData {
  behandlingData: BehandlingMedVentetider;
  [ColumnNames.Behandling]: string;
  [ColumnNames.Antall]: string;
}

export const getBehandlingerTabellData = (
  resources: HNVelgBehandlingsstedFrontend,
  behandlinger: Array<BehandlingMedVentetider> | null
): Array<BehandlingTabellData> => {
  return behandlinger
    ? behandlinger.map((behandling: BehandlingMedVentetider) => {
        return {
          behandlingData: behandling,
          [ColumnNames.Behandling]: behandling.navn ? behandling.navn : '',
          [ColumnNames.Antall]: behandling.antall ? behandling.antall.toString() : resources.behandlingsstederIkkeBeregnet,
        };
      })
    : [];
};

export const getBehandlingTabellClass = (ventetidTypesCount: number): string => {
  if (ventetidTypesCount === 2) {
    return 'vbs-behandlingssteder__behandling-tabell--three-columns';
  }
  if (ventetidTypesCount === 3) {
    return 'vbs-behandlingssteder__behandling-tabell--four-columns';
  }
  if (ventetidTypesCount === 4) {
    return 'vbs-behandlingssteder__behandling-tabell--five-columns';
  }
  if (ventetidTypesCount >= 5) {
    return 'vbs-behandlingssteder__behandling-tabell--six-columns';
  } else return '';
};

export const getTotalVentetidForBehandlingsgruppe = (
  resources: HNVelgBehandlingsstedFrontend,
  ventetiderMin: number,
  ventetiderMax: number
): string => {
  if (ventetiderMin === 0 && ventetiderMax === 0) {
    return resources.behandlingsstederOppgisIkke;
  }

  if (ventetiderMin === 0 && ventetiderMax > 0) {
    return `${ventetiderMax} ${ventetiderMax === 1 ? resources.tabellUkes : resources.tabellUkers}`;
  }

  if (ventetiderMin > 0 && ventetiderMax === 0) {
    return `${ventetiderMin} ${ventetiderMin === 1 ? resources.tabellUkes : resources.tabellUkers}`;
  }

  if (ventetiderMin === ventetiderMax) {
    return `${ventetiderMax} ${ventetiderMax === 1 ? resources.tabellUkes : resources.tabellUkers}`;
  }

  return `${ventetiderMin}-${ventetiderMax} ${resources.tabellUkers}`;
};

export const getBehandlingsstederBehandlingTableCaption = (
  resources: HNVelgBehandlingsstedFrontend,
  behandlingssted: string,
  gruppe: string
): string => {
  return `${resources.behandlingsstederBehandlingTable} ${behandlingssted}${resources.behandlingsstederBehandlingTable2} ${gruppe}${resources.behandlingsstederBehandlingTable3}`;
};
