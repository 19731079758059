import React from 'react';

import { Urls, ColumnNames } from '../types/ExtendEnums';
import {
  HentBehandlingLenkerResponse,
  Ventetid,
  VentetiderForBehandlingBehandlingssted,
} from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import AnchorLink from '@helsenorge/designsystem-react/components/AnchorLink';
import Button from '@helsenorge/designsystem-react/components/Button';
import Title from '@helsenorge/designsystem-react/components/Title';

import getFeatureToggle from '@helsenorge/framework-utils/hn-feature-toggle';
import { getHelsenorgeUrl } from '@helsenorge/framework-utils/hn-proxy-service';

import VentetidComponent from './ventetid';
import { sortVentetider } from '../behandlingssteder/table-helper';
import AdresseComponent from '../shared/adresse';
import TelefonComponent from '../shared/telefon';
import { hentHelseregionAvtaleString } from '../utils/strings';

export interface TabellData {
  behandlingsstedData: VentetiderForBehandlingBehandlingssted;
  [ColumnNames.Behandlingssted]: string;
  ventetid: string;
}

interface Props {
  rowData?: TabellData;
  expanded?: boolean;
  resources: HNVelgBehandlingsstedFrontend;
  getLinks: (orgNr: number) => Promise<HentBehandlingLenkerResponse | null>;
}

const BehandlingsstedExpanded: React.FunctionComponent<Props> = ({ rowData, expanded, resources, getLinks }: Props) => {
  const behandlingsstedData = rowData?.behandlingsstedData;
  const [showContent, setShowContent] = React.useState(false);
  const [lenker, setLenker] = React.useState<HentBehandlingLenkerResponse | null>(null);
  const [lenkerOrgNr, setLenkerOrgNr] = React.useState(0);
  const harVentetider = !!behandlingsstedData?.ventetider && behandlingsstedData.ventetider.length > 0;
  const seVentetiderUrl = `${getHelsenorgeUrl()}${Urls.SlikVelgerDu}`;

  const getHostname = (url: string): string => {
    const domain = new URL(url);
    return domain.hostname.replace('www.', '');
  };

  React.useEffect(() => {
    if (lenkerOrgNr !== behandlingsstedData?.orgnummer) {
      setLenker(null);
      setLenkerOrgNr(0);
    }
  }, [behandlingsstedData?.orgnummer]);

  React.useEffect(() => {
    let mounted = true;

    if (
      expanded &&
      lenkerOrgNr !== behandlingsstedData?.orgnummer &&
      getFeatureToggle('VBSBehandlingsLenker') &&
      behandlingsstedData?.orgnummer
    ) {
      getLinks(behandlingsstedData.orgnummer).then(links => {
        if (!mounted) return;
        setLenkerOrgNr(behandlingsstedData.orgnummer);
        setLenker(links);
      });
    }
    if (expanded && !showContent) {
      setShowContent(true);
    }

    return (): void => {
      mounted = false;
    };
  }, [expanded]);

  const ventetider = behandlingsstedData?.ventetider ? [...behandlingsstedData.ventetider] : undefined;

  ventetider?.sort(sortVentetider);

  return showContent && behandlingsstedData ? (
    <div className="vbs-main__expanded-inner-content">
      <Title htmlMarkup="h2" appearance={'title2'} margin={1}>
        {behandlingsstedData.navn}
      </Title>

      {behandlingsstedData.helseRegioner || behandlingsstedData.avtaleMedHelfo || !!behandlingsstedData.kommentar ? (
        <section>
          {behandlingsstedData.avtaleMedHelfo && <span>{resources.ventetiderForBehandlingAvtaleMedHelfo}</span>}
          <br />
          {behandlingsstedData.helseRegioner && behandlingsstedData.erPrivat ? (
            <div>
              {behandlingsstedData.helseRegioner.map((region: string, index: number) => (
                <div key={index}>{hentHelseregionAvtaleString(region, resources)}</div>
              ))}
            </div>
          ) : null}

          {!!behandlingsstedData.kommentar && <p>{behandlingsstedData.kommentar}</p>}
        </section>
      ) : null}

      {harVentetider && (
        <Title htmlMarkup="h3" appearance={'title3'} margin={0.5}>
          {resources.ventetiderForBehandlingTabellVentetid}
        </Title>
      )}

      {harVentetider || !behandlingsstedData.harVurderingskompetanse ? (
        <p>
          {ventetider?.map((ventetid: Ventetid, index: number) => (
            <VentetidComponent key={ventetid.type} resources={resources} ventetid={ventetid} lineBreak={index < ventetider.length - 1} />
          ))}

          {!behandlingsstedData.harVurderingskompetanse && (
            <span>
              <br />
              <strong>{resources.ventetiderForBehandlingHenvisningVurderingObs} </strong>
              {resources.ventetiderForBehandlingHenvisningVurdering}
            </span>
          )}
        </p>
      ) : null}

      {lenker && lenker.behandlingLenke && lenker.behandlingTittel && (
        <>
          <Title htmlMarkup="h3" appearance={'title3'} margin={0.5}>
            {resources.behandlingsstederTabellBehandling}
          </Title>
          <div className="vbs-ventetider-for-behandling__anchor">
            <AnchorLink href={lenker.behandlingLenke} target="_blank">
              {`${lenker.behandlingTittel} (${getHostname(lenker.behandlingLenke)})`}
            </AnchorLink>
          </div>
        </>
      )}

      <Title htmlMarkup="h3" appearance={'title3'} margin={1}>
        {resources.ventetiderForBehandlingKontakt}
      </Title>

      {lenker?.avdelingLenke && lenker.avdelingTittel && (
        <div className="vbs-ventetider-for-behandling__anchor">
          <AnchorLink href={lenker.avdelingLenke} target="_blank">
            {`${lenker.avdelingTittel} (${getHostname(lenker.avdelingLenke)})`}
          </AnchorLink>
          <br />
        </div>
      )}

      <div className="vbs-ventetider-for-behandling__contact-info">
        <div>
          {!!behandlingsstedData.besoksAdresse && !!behandlingsstedData.besoksAdresse.gateadresse && (
            <>
              <Title htmlMarkup="h4" appearance={'title4'} margin={0}>
                {resources.tabellBesoksadresse}
              </Title>
              <AdresseComponent adresse={behandlingsstedData.besoksAdresse} />
            </>
          )}

          {behandlingsstedData.telefon && (
            <>
              <Title htmlMarkup="h4" appearance={'title4'} margin={0}>
                {resources.tabellTelefon}
              </Title>
              <TelefonComponent telefon={behandlingsstedData.telefon} />
            </>
          )}
        </div>

        <div>
          {!!behandlingsstedData.postAdresse && !!behandlingsstedData.postAdresse.gateadresse && (
            <>
              <Title htmlMarkup="h4" appearance={'title4'} margin={0}>
                {resources.ventetiderForBehandlingPostadresse}
              </Title>
              <AdresseComponent adresse={behandlingsstedData.postAdresse} />
            </>
          )}
        </div>
      </div>

      <Button href={seVentetiderUrl} htmlMarkup="a" wrapperClassName="vbs-ventetider-for-behandling__link">
        {resources.ventetiderForBehandlingVelgBehandlingssted}
      </Button>
    </div>
  ) : null;
};

export default BehandlingsstedExpanded;
