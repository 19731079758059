import React from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Urls } from '../types/ExtendEnums';
import {
  BehandlingsHovedGruppe,
  BehandlingsUnderGruppe,
  Behandling,
} from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import ExpanderList from '@helsenorge/designsystem-react/components/ExpanderList';
import HighlightPanel from '@helsenorge/designsystem-react/components/HighlightPanel';
import LazyIcon from '@helsenorge/designsystem-react/components/LazyIcon';
import Title from '@helsenorge/designsystem-react/components/Title';
import designsystemtypography from '@helsenorge/designsystem-react/scss/typography.module.scss';

import { Suggestion } from '@helsenorge/autosuggest/components/autosuggest';
import PageHeader from '@helsenorge/core-framework/page-header';
import PageLoader from '@helsenorge/core-framework/page-loader/page-loader';
import { LanguageLocales } from '@helsenorge/core-utils/constants/languages';
import { getSidetittelId } from '@helsenorge/framework-utils/hn-page';
import { getResourcesFromState } from '@helsenorge/framework-utils/resources';

import { hentBehandlinger, getBehandlingerLoading, getBehandlingerError, getHovedgrupper } from './behandlinger';
import AutosuggestComponent from '../shared/autosuggest';
import { hentSokBehandlinger, getSokBehandlingerLoading, getSokBehandlingerSuggestions } from '../shared/autosuggest/sok-behandlinger';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { behandlingSvgIkonMapper } from '../utils/behandling-ikon-mapper';
import { HN_VELG_BEHANDLINGSSTED_FRONTEND } from '../utils/resources-project';

import './styles.scss';

const Behandlinger: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loadingBehandlinger = useAppSelector(getBehandlingerLoading);
  const errorBehandlinger = useAppSelector(getBehandlingerError);
  const hovedgrupper = useAppSelector(getHovedgrupper);

  const loadingSokBehandlinger = useAppSelector(getSokBehandlingerLoading);
  const allSuggestions = useAppSelector(getSokBehandlingerSuggestions);

  const resources = useAppSelector(
    state => getResourcesFromState(state, HN_VELG_BEHANDLINGSSTED_FRONTEND, LanguageLocales.NORWEGIAN) as HNVelgBehandlingsstedFrontend
  );

  const handleSuggestionSelected = (suggestion: Suggestion): void => {
    navigate(`${Urls.VentetiderForBehandling}?bid=${suggestion.value}`);
  };

  React.useEffect(() => {
    dispatch(hentBehandlinger(resources));
    dispatch(hentSokBehandlinger());
  }, []);

  return (
    <PageLoader
      resources={resources}
      loading={loadingBehandlinger || loadingSokBehandlinger}
      error={errorBehandlinger}
      labelId={getSidetittelId()}
    >
      <PageHeader title={resources.behandlingerTittel} hjelpetekstId="allebehandlinger" showHjelpetrigger />

      <HighlightPanel color={'neutral'} size={'fluid'}>
        <AutosuggestComponent
          label={resources.behandlingerSokLabel}
          allSuggestions={allSuggestions ? allSuggestions : []}
          handleSuggestionSelected={handleSuggestionSelected}
        />
      </HighlightPanel>

      {hovedgrupper?.map((hovedgruppe: BehandlingsHovedGruppe | null, index: number) => {
        return (
          hovedgruppe && (
            <section className="vbs-behandlinger__hovedgruppe" key={index}>
              <Title htmlMarkup="h2" appearance={'title2'} margin={1}>
                {hovedgruppe.navn}
              </Title>

              <ExpanderList color="neutral">
                {hovedgruppe.behandlingsUnderGrupper?.map((undergruppe: BehandlingsUnderGruppe, index2: number) => {
                  return (
                    <ExpanderList.Expander
                      testId="undergruppe-button"
                      key={index2}
                      icon={<LazyIcon iconName={behandlingSvgIkonMapper(undergruppe.kode)} />}
                      title={undergruppe.navn ? undergruppe.navn : ''}
                      color="white"
                    >
                      <ul
                        className={`vbs-behandlinger__undergruppe ${designsystemtypography['anchorlink-wrapper']}`}
                        data-testid="undergruppe"
                      >
                        {undergruppe.behandlinger?.map((behandling: Behandling, index: number) => {
                          return (
                            <li key={index}>
                              <Link to={`${Urls.VentetiderForBehandling}?bid=${behandling.behandlingsId}`}>{behandling.navn}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    </ExpanderList.Expander>
                  );
                })}
              </ExpanderList>
            </section>
          )
        );
      })}
    </PageLoader>
  );
};

export default Behandlinger;
