import { createReducer, ThunkDispatch } from '@reduxjs/toolkit';

import { BehandlingerResponse, BehandlingsHovedGruppe } from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import { get } from '@helsenorge/framework-utils/hn-proxy-service';
import { error } from '@helsenorge/framework-utils/logger';

import { RootState } from '../store';
import { GlobalAction } from '../store/reducer';

/** ACTION TYPES */

const BEHANDLINGER_LOAD = 'velg-behandlingssted/BEHANDLINGER_LOAD';
const BEHANDLINGER_LOAD_SUCCESS = 'velg-behandlingssted/BEHANDLINGER_LOAD_SUCCESS';
const BEHANDLINGER_LOAD_FAIL = 'velg-behandlingssted/BEHANDLINGER_LOAD_FAIL';

/** ACTIONS */

interface BehandlingerLoadAction {
  type: typeof BEHANDLINGER_LOAD;
}

interface BehandlingerLoadSuccessAction {
  type: typeof BEHANDLINGER_LOAD_SUCCESS;
  hovedgrupper: Array<BehandlingsHovedGruppe> | null;
}

interface BehandlingerLoadFailAction {
  type: typeof BEHANDLINGER_LOAD_FAIL;
  error: string;
}

export type BehandlingerAction = BehandlingerLoadAction | BehandlingerLoadSuccessAction | BehandlingerLoadFailAction;

/** ACTION CREATORS */

export const loadBehandlinger = (): BehandlingerLoadAction => {
  return { type: BEHANDLINGER_LOAD };
};

export const successBehandlinger = (hovedgrupper: Array<BehandlingsHovedGruppe> | null): BehandlingerLoadSuccessAction => {
  return { type: BEHANDLINGER_LOAD_SUCCESS, hovedgrupper: hovedgrupper };
};

export const failedBehandlinger = (error: string): BehandlingerLoadFailAction => {
  return { type: BEHANDLINGER_LOAD_FAIL, error: error };
};

/** REDUCER */

export type BehandlingerState = {
  loading: boolean;
  hovedgrupper?: Array<BehandlingsHovedGruppe> | null;
  error?: string;
};

export const initialState = { loading: false };

const behandlinger = createReducer<BehandlingerState>(initialState, builder => {
  builder
    .addCase(BEHANDLINGER_LOAD, state => {
      state.loading = true;
    })
    .addCase(BEHANDLINGER_LOAD_SUCCESS, (state, action: BehandlingerLoadSuccessAction) => {
      state.loading = false;
      state.hovedgrupper = action.hovedgrupper;
    })
    .addCase(BEHANDLINGER_LOAD_FAIL, (state, action: BehandlingerLoadFailAction) => {
      state.loading = false;
      state.error = action.error;
    });
});

/** STATE SELECTORS */

export function getBehandlingerState(state: RootState): BehandlingerState {
  if (!state.behandlinger) {
    error('the behandlinger state is missing in this reducer');
  }
  return state.behandlinger;
}

export function getBehandlingerLoading(state: RootState): boolean {
  return getBehandlingerState(state).loading;
}

export function getBehandlingerError(state: RootState): string | undefined {
  return getBehandlingerState(state).error;
}

export function getHovedgrupper(state: RootState): Array<BehandlingsHovedGruppe> | undefined | null {
  return getBehandlingerState(state).hovedgrupper;
}

/** API */

export function hentBehandlinger(resources: HNVelgBehandlingsstedFrontend) {
  return (dispatch: ThunkDispatch<RootState, void, GlobalAction>, getState: () => RootState): Promise<void> | undefined => {
    const state = getState();

    if (!getBehandlingerLoading(state) && !getHovedgrupper(state)) {
      dispatch(loadBehandlinger());

      return get<BehandlingerResponse>('VelgBehandlingssted', 'api/v1/Behandlinger')
        .then((data: BehandlingerResponse) => {
          dispatch(successBehandlinger(data.behandlingsGrupper));
        })
        .catch(() => {
          dispatch(failedBehandlinger(resources.tekniskFeilBehandlinger));
        });
    }
  };
}

export default behandlinger;
