import React from 'react';

import { VentetiderForBehandlingBehandlingssted } from '../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';
import { HNVelgBehandlingsstedFrontend } from '../types/Resources';

import { Cell } from '@helsenorge/data-table/components/table';

import { hentAntallBehandlingerString } from './helper';

export interface RowData {
  behandlingsstedData: VentetiderForBehandlingBehandlingssted;
}

interface Props {
  resources: HNVelgBehandlingsstedFrontend;
  rowData?: RowData;
}

export default class AntallCell extends React.PureComponent<Props> {
  render(): JSX.Element | null {
    const { resources, rowData, ...other } = this.props;

    return (
      <Cell {...other}>
        {rowData && rowData.behandlingsstedData.antall ? (
          <>
            <span>{hentAntallBehandlingerString(resources, rowData.behandlingsstedData.antall)}</span>
            <br />
            <span className="vbs-main__subtitle">{`${resources.tabellAntallBehandlingerIAar} ${rowData.behandlingsstedData.antallAr}`}</span>
          </>
        ) : (
          <span className="vbs-main__subtitle">{resources.behandlingsstederIkkeBeregnet}</span>
        )}
      </Cell>
    );
  }
}
