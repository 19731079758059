import React from 'react';

import { Adresse } from '../../types/Helsenorge.VelgBehandlingssted.Libraries.EntitiesEntities';

interface Props {
  adresse: Adresse;
}

const AdresseComponent: React.FunctionComponent<Props> = ({ adresse }: Props) => {
  return (
    <p>
      <span>{adresse.gateadresse}</span>

      {adresse.postnr || adresse.poststed ? (
        <>
          <br />
          <span>{`${adresse.postnr} ${adresse.poststed}`}</span>
        </>
      ) : null}
    </p>
  );
};

export default AdresseComponent;
